<!--主页-->
<template>
  <!--通知公告s-->
  <div class="box-p">
    <el-row>
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="12" :md="11" :lg="10" class="pr_15">
        <div class="box-tit"><a href="/message/">通知公告</a><a href="/message/" class="a_tit">更多</a></div>
        <div class="nlist pb_15">
          <ul>
            <li v-for="(item,index) in messageData" :key="index">
              <!-- <span>· </span><router-link :to="{ path: '/message/message', query: { id: item.id }}" target="_blank">{{item.title.substring(0,28)}}<span>{{formatDate(item.datetimes)}}</span></router-link> -->
              <p><span>·</span><a :href="`/message/message?id=${item.id}`" target="_blank">{{item.title.substring(0,26)}}</a></p>
              <time>{{formatDate(item.datetimes)}}</time>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :sm="12" :md="11" :lg="10">
        <!-- 资源公示s -->
        <div class="box-tit"><a href="/news/index?id=1552208311489413122">资源公示</a><a href="/news/index?id=1552208311489413122" class="a_tit">更多</a></div>
        <div class="nlist pb_15">
          <ul>
            <li v-for="(item,index) in newsData" :key="index">              
              <!-- <span>· </span><a :href="`/news/news?id=${item.id}`" target="_blank">{{item.contentTitle.substring(0,26)}}<span>{{formatDate(item.contentDatetime)}}</span></a> -->
              <p><span>·</span><a :href="`/news/news?id=${item.id}`" target="_blank">{{item.contentTitle.substring(0,24)}}</a></p>
              <time>{{formatDate(item.contentDatetime)}}</time>
            </li>
          </ul>
        </div>
        <!-- 资源公示e -->
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--通知公告e-->
  <!--创新资源s-->
  <div class="box-p main_bgcolor">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <div class="box-tit"><router-link to="/resource/" class="a1">创新资源</router-link> <a href="/resource/" class="a_tit">更多</a></div>   
        <el-row :gutter="16">
          <el-col :sm="12" :md="6" :lg="6" v-for="(item,index) in resData" :key="index">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a :href="`/resource/resource?id=${item.id}`" target="_blank"><img :src='showImage(item.userId)' /></a>
              <div class="card-body">
                <div class="card-title"><a :href="`/resource/resource?id=${item.id}`" target="_blank">{{item.name.substring(0,14)}}</a></div>
                <div class="card-text" v-html="item.content"></div>
                <div class="card-bottom">
                  <time>{{formatDate(item.createDate)}}</time>
                  <a :href="`/resource/resource?id=${item.id}`" target="_blank"><el-button type="primary" plain>详细内容</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>    
        <!-- <el-row :gutter="20">
          <el-col :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img src="@/assets/images/1004.jpg" />
              <div class="card-body">
                <div class="card-title">产学研资源标题名称</div>
                <p class="card-text">欧洲精英MINI拥有超乎想象的迷你设计，只有常规壁挂炉的一半尺寸，瘦身安装空间。出色的采暖和舒适热水供应性能，轻松满足小户型的使用需求!</p>
                <div class="card-bottom">
                  <time class="card-time">2022-07-04 18:40</time>
                  <a href="#" target="_blank"><el-button type="primary" plain>详细内容</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img src="@/assets/images/1017.jpg" />
              <div class="card-body">
                <div class="card-title">产学研资源标题名称</div>
                <p class="card-text">欧洲精英MINI拥有超乎想象的迷你设计，只有常规壁挂炉的一半尺寸，瘦身安装空间。出色的采暖和舒适热水供应性能，轻松满足小户型的使用需求!</p>
                <div class="card-bottom">
                  <time class="card-time">2022-07-04 18:40</time>
                  <a href="#" target="_blank"><el-button type="primary" plain>详细内容</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img src="@/assets/images/1004.jpg" />
              <div class="card-body">
                <div class="card-title">产学研资源标题名称</div>
                <p class="card-text">欧洲精英MINI拥有超乎想象的迷你设计，只有常规壁挂炉的一半尺寸，瘦身安装空间。出色的采暖和舒适热水供应性能，轻松满足小户型的使用需求!</p>
                <div class="card-bottom">
                  <time class="card-time">2022-07-04 18:40</time>
                  <a href="#" target="_blank"><el-button type="primary" plain>详细内容</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img src="@/assets/images/1017.jpg" />
              <div class="card-body">
                <div class="card-title">产学研资源标题名称</div>
                <p class="card-text">欧洲精英MINI拥有超乎想象的迷你设计，只有常规壁挂炉的一半尺寸，瘦身安装空间。出色的采暖和舒适热水供应性能，轻松满足小户型的使用需求!</p>
                <div class="card-bottom">
                  <time class="card-time">2022-07-04 18:40</time>
                  <a href="#" target="_blank"><el-button type="primary" plain>详细内容</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>       -->
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--创新资源e-->
  <!--科技成果s -->
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="12" :md="11" :lg="10" >
        <div class="box-tit"><a href="/news/index?id=1552208311489413122">科技成果</a><a href="/news/index?id=1552208311489413122" class="a_tit">更多</a></div>
        <el-row :gutter="16">
          <!-- <el-col :xs="12" :sm="12" :md="12" :lg="12" v-for="(item,index) in resData" :key="index">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a :href="`/resource/resource?id=${item.id}`" target="_blank"><img :src='showImage(item.userId)' /></a>
              <div class="card-body">
                <div class="card-title1"><a :href="`/resource/resource?id=${item.id}`" target="_blank">{{item.name.substring(0,30)}}</a></div>                
              </div>
            </el-card>
          </el-col> -->
          <el-col :xs="12" :sm="12" :md="12" :lg="12">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/resource/resource?id=" target="_blank"><img src="@/assets/images/b1.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/resource/resource?id=" target="_blank">科技成果转化</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/resource/resource?id=" target="_blank"><img src="@/assets/images/b2.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/resource/resource?id=" target="_blank">专利技术专利证书</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/resource/resource?id=" target="_blank"><img src="@/assets/images/b3.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/resource/resource?id=" target="_blank">科技成果展示</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/resource/resource?id=" target="_blank"><img src="@/assets/images/b4.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/resource/resource?id=" target="_blank">科技成果展示</a></div>                
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :sm="12" :md="11" :lg="10">
        <div class="box-tit"><a href="/talents/tutorlist">创业导师</a><a href="/talents/tutorlist" class="a_tit">更多</a></div>       
        <el-row :gutter="16">
          <el-col :xs="12" :sm="12" :md="12" :lg="12">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/resource/resource?id=" target="_blank"><img src="@/assets/images/a3.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/resource/resource?id=" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/resource/resource?id=" target="_blank"><img src="@/assets/images/a2.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/resource/resource?id=" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/resource/resource?id=" target="_blank"><img src="@/assets/images/a1.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/resource/resource?id=" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/resource/resource?id=" target="_blank"><img src="@/assets/images/a4.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/resource/resource?id=" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--科技成果e-->
  <!--产学研对接s-->
  <div class="box-p main_bgcolor">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <div class="box-tit"><a href="/activity/" class="a1" >产学研对接</a> <a href="/activity/" class="a_tit">更多</a></div>   
        <el-row :gutter="16">
          <el-col :sm="12" :md="6" :lg="6" v-for="(item,index) in resData" :key="index">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a :href="`/resource/resource?id=${item.id}`" target="_blank"><img :src='showImage(item.userId)' /></a>
              <div class="card-body">
                <div class="card-title"><a :href="`/resource/resource?id=${item.id}`" target="_blank">{{item.name.substring(0,14)}}</a></div>
                <!-- <div class="card-text" v-html="item.content"></div> -->
                <div class="card-bottom">
                  <time>活动时间 {{formatDate(item.createDate)}}</time>
                  <a :href="`/resource/resource?id=${item.id}`" target="_blank"><el-button type="primary" plain>立即报名</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>        
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--产学研对接e-->
  <!-- 实践示范s -->
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="12" :md="11" :lg="10" >
        <div class="box-tit"><a href="/news/index?id=1552208311489413122">实践示范</a> <a href="/news/index?id=1552208311489413122" class="a_tit">更多</a></div>
        <div class="nlist pb_15">
          <ul>
            <li v-for="(item,index) in newsData" :key="index">              
              <!-- <span>· </span><a :href="`/news/news?id=${item.id}`" target="_blank">{{item.contentTitle.substring(0,26)}}<span>{{formatDate(item.contentDatetime)}}</span></a> -->
              <span>· <a :href="`/news/news?id=${item.id}`" target="_blank">{{item.contentTitle.substring(0,24)}}</a></span>
                  <time>{{formatDate(item.contentDatetime)}}</time>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :sm="12" :md="11" :lg="10">
        <div class="box-tit">公共服务平台</div>
        <div class="link pb_15">
          <ul>
            <li v-for="(item,index) in linkData" :key="index">
              <a :href="item.url" target="_blank">{{item.name}}</a>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--实践示范e-->
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'

export default {
  name: 'index',
  data () {
    return {
      messageData: [],
      resData: [],
      newsData: [],
      linkData: []
    }
  },
  mounted () {
    // document.title = '云南省大学科技园'
    this.getMessageList()
    this.getResList()
    this.getNewsList()
    this.getLinkList()
  },
  methods: {
    // 通知公告
    async getMessageList () {
      const params = '?status=0&size=6' 
      const { data: ret } = await this.$http.get(this.$api.messagelist + params)     
      // console.log('ret=', ret)
      if (ret.code === 1) {
        this.messageData = ret.data.records
        // console.log('rows=', this.messageData)
      } else {
        this.$message.error(ret.msg)        
      }
    },
    // 产学研资源
    async getResList () {
      // status=1&
      const params = '?size=4'
      const { data: ret } = await this.$http.get1(this.$api.resourcelist + params)      
      //console.log('ret=', ret)
      if (ret.code === 1) {
        this.resData = ret.data.records
      } else {
        this.$message.error(ret.msg)        
      }     
    },
    // 资源公示
    async getNewsList () {
      const params = '?categoryId=1552208311489413122' + '&size=6'
      const { data: ret } = await this.$http.get1(this.$api.newslist + params)      
      // console.log('retNews=', ret)
      if (ret.code === 1) {
        this.newsData = ret.data.records
      } else {
        this.$message.error(ret.msg)        
      }     
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){
      return formatDate(time,'yyyy-MM-dd')
    },
    showImage(val){
      if(val === null) {        
        return '/images/nopic1.jpg'
      } else {        
        var arr = JSON.parse(val)
        var imgurl = this.$api.serverUrl + arr[0].url
        return imgurl
      }
    },
    // 快速入口（友情链接）
    async getLinkList () {
      const params = '?type=1&size=12' 
      const { data: ret } = await this.$http.get(this.$api.menulist + params)     
      // console.log('ret=', ret)
      if (ret.code === 1) {
        this.linkData = ret.data.records
      } else {
        this.$message.error(ret.msg)        
      }
    }
  }
}
</script>

<style>

</style>
